import React from 'react';
import { Box, Button, Grid, Text, Spinner } from '@chakra-ui/react';
import map from 'lodash/map';
import useScreenSize from 'hooks/useScreenSize';
import VideoCardElement from './VideoCardElement';

export const PexelsVideoAllGrid = ({ onClick, data }) => {
  return (
    <Grid
      templateColumns="repeat(auto-fit, minmax(90px, 1fr))"
      gap={3}
      data-testid="pexel-video--grid"
      justifyContent="center"
      alignItems="flex-start"
    >
      {data && (
        <>
          {map(data, (videoData) => {
            return (
              <Box
                key={videoData.id}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
              >
                <Box
                  w="90px"
                  h="100px"
                  bg="charadeDark3"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius={5}
                  color="azure"
                  data-testid="titles-grid-box"
                  onClick={() => {}}
                >
                  <VideoCardElement data={videoData} onClick={onClick} />
                </Box>
              </Box>
            );
          })}
        </>
      )}
    </Grid>
  );
};
