import React from 'react';
import { Box, Button, Grid, Text, Spinner } from '@chakra-ui/react';
import map from 'lodash/map';
import take from 'lodash/take';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useSidebarRepository } from 'repositories';
import useScreenSize from 'hooks/useScreenSize';
import usePexelsApi from 'api/usePexelsApi';
import VideoCardElement from './VideoCardElement';

export const PexelsVideoGrid = ({ onClick, searchText }) => {
  const sidebarMenuRepo = useSidebarRepository();
  const { setSearchTerm, data, isLoading } = usePexelsApi();

  React.useEffect(() => {
    setSearchTerm(searchText || 'nature');
  }, [searchText]);

  return (
    <Grid
      templateColumns="repeat(auto-fit, minmax(90px, 1fr))"
      gap={3}
      data-testid="pexel-video--grid"
      justifyContent="center"
      alignItems="flex-start"
    >
      {!isLoading && data && (
        <>
          {map(take(data, 5), (videoData) => {
            return (
              <Box
                key={videoData.id}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
              >
                <Box
                  w="90px"
                  h="100px"
                  bg="charadeDark3"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius={5}
                  color="azure"
                  data-testid="titles-grid-box"
                  onClick={() => {}}
                >
                  <VideoCardElement data={videoData} onClick={onClick} />
                </Box>
              </Box>
            );
          })}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              w="90px"
              h="80px"
              bg="charadeDark3"
              color="azure"
              fontSize="sm"
              borderRadius={5}
              onClick={() => sidebarMenuRepo.update('Elements/PexelVideos')}
              data-testid="view-all-button"
            >
              <FiMoreHorizontal color="white" />
            </Button>
            <Text fontSize="xx-small" align="center" color="pearl" mt={1}>
              View All
            </Text>
          </Box>
        </>
      )}
      {isLoading && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner alignSelf="center" size={'lg'} />
          <Text fontSize="xx-small" align="center" color="pearl" mt={1}>
            fetching...
          </Text>
        </Box>
      )}
    </Grid>
  );
};
