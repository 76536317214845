import React from 'react';
import { Card, CardHeader, CardBody, Image } from '@chakra-ui/react';
import { Hover } from '../../hover';
const VideoCardElement = ({ data, onClick }) => {
  const { id, image, width } = data;

  const handleOnVideoClick = () => {
    const { duration, video_files, image } = data;
    let selectedVideo = video_files.find((file) => file.quality === 'uhd');
    if (!selectedVideo) {
      selectedVideo = video_files.find((file) => file.quality === 'hd');
    }
    if (!selectedVideo) {
      selectedVideo = video_files[0];
    }
    const { link: videoLink, width, height } = selectedVideo || {};
    const videoData = {
      id,
      videoLink,
      duration: 1000 * duration,
      width,
      height,
      thumbUrl: image,
      droptype: 'videos',
    };
    onClick(videoData);
  };

  return (
    <Hover onAddClick={handleOnVideoClick} onClick={handleOnVideoClick}>
      <Image
        src={image}
        alt="Pexels Video"
        borderRadius="xs"
        boxSize="95px"
        objectFit="cover"
      />
    </Hover>
  );
};

export default VideoCardElement;
