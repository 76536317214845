import React, { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const usePexelsApi = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(10);
  const [searchTerm, setSearchTerm] = React.useState('Nature');
  const [vidData, setVidData] = React.useState([]);

  /** Axios client */
  const fetchPexelsVideoTemplates = useCallback(async () => {
    const response = await axios.get(
      `https://api.pexels.com/videos/search?query=${searchTerm}&size=medium&per_page=15`,
      {
        headers: {
          Authorization:
            'M8qVc0OkBJ7vhDhWXKKpr9gjThBNEzXuOPvOCUy0VooBVRTSH0YMwM8D',
        },
      }
    );
    const result = response.data.videos.map((vid) => {
      return {
        id: vid.id,
        width: vid.width,
        height: vid.height,
        duration: vid.duration,
        image: vid.image,
        user: vid.user,
        pexelLink: vid.url,
        video_files: vid.video_files,
      };
    });
    return result;
  }, [searchTerm]);

  const { isLoading, data } = useQuery(
    ['FETCH_PEXELS_VIDEO_TEMPLATES', searchTerm],
    fetchPexelsVideoTemplates
  );

  return {
    data: data || vidData,
    searchTerm,
    setSearchTerm,
    currentPage,
    setCurrentPage,
    setPageLimit,
    pageLimit,
    isLoading,
  };
};

export default usePexelsApi;
